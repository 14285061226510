/*=========================================================================================
  File Name: moduleLanguage.js
  Description: Language Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleMedicalPackageCategoryState.js'
import mutations from './moduleMedicalPackageCategoryMutations.js'
import actions from './moduleMedicalPackageCategoryActions.js'
import getters from './moduleMedicalPackageCategoryGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}