/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
    AddCountry({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/Country/AddCountry", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllCountries({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("api/Country/GetAllCountries")
                .then((response) => {
                    commit('SET_Country', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetTopDestinations({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/SearchCountries", { "IsTopDestinations": true })
                .then(response => {
                    commit("SET_TopDestinations", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetDoctorCountry({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Country/SearchCountries", { "IsAvailableToDoctors": true })
                .then(response => {
                    commit("SET_DoctorCountry", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdateCountry({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/Country/UpdateCountry", item)
                .then((response) => {
                    commit('UPDATE_Country', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetAllPackageCountries({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Country/GetAllPackageCountries")
                .then(response => {
                    commit("SET_Country", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetCountry({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.post("api/Country/GetCountry", itemid)
                .then((response) => {
                    commit('UPDATE_Country', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteCountry({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/Country/DeleteCountry?ID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}