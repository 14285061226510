<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <!-- search card -->
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-1"
      collapse-action
    >
      <div class="vx-row">
        <!-- DateFrom -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="ReservationSessionSearch.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <!-- DateTo -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="ReservationSessionSearch.DateTo"
            class="w-full"
            name="to"
          ></datepicker>
        </div>
        <!-- PatientID -->
        <div
          v-show="ReservationSessionSearch.IsPrivate != true"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("Patients") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ReservationSessionSearch.PatientID"
            :options="Patients"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- MedicalPackageName -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{
            $t("Medical Package Name")
          }}</label>
          <vs-input class="w-full" v-model="ReservationSessionSearch.MedicalPackageName" />
        </div>
        <!-- MedicalPackageCategoryID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{
            $t("Medical Package Categories")
          }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ReservationSessionSearch.MedicalPackageCategoryID"
            :options="MedicalPackageCategories"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- MedicalPackageCountryID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{
            $t("Medical Package Destination")
          }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ReservationSessionSearch.MedicalPackageCountryID"
            :options="countries"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- StatusID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ReservationSessionSearch.StatusID"
            :options="status"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- PaymentStatusID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ReservationSessionSearch.PaymentStatusID"
            :options="PaymentStatus"
            :reduce="(ID) => ID.ID"
          />
        </div>

        <div class="vx-col w-full">
          <div class="vx-row">
            <vs-button
              style="margin: 10px"
              class="my-3"
              @click="reservationPackagesSearch"
              v-scroll-to="'#Scroll'"
              >{{ $t("Search") }}</vs-button
            >
            <vs-button
              style="margin-left: 10px"
              class="my-3"
              @click="resetData"
              >{{ $t("Reset") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Reservations"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                Reservations.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : Reservations.length
              }}
              of {{ Reservations.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <!-- header -->
      <template slot="thead">
        <vs-th sort-key="Doctor">{{ $t("Patient") }}</vs-th>
        <vs-th sort-key="Doctor">{{ $t("Reference Number") }}</vs-th>
        <vs-th sort-key="Doctor">{{ $t("Package Name") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Date ") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Price") }}</vs-th>
        <!-- <vs-th sort-key="Day">{{ $t("Discount") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Price after Discount") }}</vs-th> -->
        <vs-th sort-key="Day">{{ $t("Status") }}</vs-th>
        <vs-th>{{ $t("Action") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p v-if="tr.Patient" class="product-name font-medium truncate">
                {{ tr.Patient.NameEN }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.ReferenceNumber }}
              </p>
            </vs-td>
            <vs-td>
              <p
                v-if="tr.MedicalPackage"
                class="product-name font-medium truncate"
              >
                {{ tr.MedicalPackage.Name }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.PackageStartDate | formatDate() }}
              </p>
            </vs-td>

            <vs-td>
              <p
                v-if="tr.MedicalPackage"
                class="product-name font-medium truncate"
              >
                {{ tr.PriceInUSD }}USD
              </p>
            </vs-td>
            <!-- <vs-td>
              <p
                v-if="tr.MedicalPackage"
                class="product-name font-medium truncate"
              >
                {{ tr.MedicalPackage.Discount }}
              </p>
            </vs-td> -->
            <!-- <vs-td>
              <p
                v-if="tr.MedicalPackage"
                class="product-name font-medium truncate"
              >
                {{ tr.MedicalPackage.PriceAfterDiscount }}
              </p>
            </vs-td> -->
            <vs-td>
              <p v-if="tr.Status" class="product-name font-medium truncate">
                {{ tr.Status.NameEN }}
              </p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <router-link
                :to="{
                  name: 'ReservationMedicalPackage-Details',
                  params: { ID: tr.ID },
                }"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                />
              </router-link>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleReservation from "@/store/reservation/ReservationSession/moduleReservation.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleMedicalPackageCategory from "@/store/settings/MedicalPackageCategory/moduleMedicalPackageCategory.js";
import moduleStatus from "@/store/settings/status/moduleStatus.js";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      selected: [],
      search: { StatusID: 1 },

      itemsPerPage: 10,
      isMounted: false,
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
     ReservationSessionSearch() {
      debugger;
      return this.$store.state.ReservationList.ReservationSessionSearchObj;
    },
    status() {
      return this.$store.state.StatusList.status;
    },
    PaymentStatus() {
      return this.$store.state.PaymentStatusList.PaymentStatus;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Reservations() {
      return this.$store.state.patientList.ReservationPackages;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    Patients() {
      return this.$store.state.patientList.patients;
    },
    MedicalPackageCategories() {
      return this.$store.state.MedicalPackageCategoryList
        .MedicalPackageCategorys;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },

  methods: {
    resetData() {
      debugger
      this.ReservationSessionSearch = { StatusID: 1 };

      this.ReservationSessionSearch.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.ReservationSessionSearch.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
          this.ReservationSessionSearch.PatientID=null;
          this.ReservationSessionSearch.MedicalPackageCountryID=null;
          this.ReservationSessionSearch.MedicalPackageCategoryID=null;
          this.ReservationSessionSearch.MedicalPackageName=null;
          this.ReservationSessionSearch.PaymentStatusID=null;
          this.ReservationSessionSearch.PatientIDs=null;
          this.reservationPackagesSearch();
    },
    reservationPackagesSearch() {
      const ID = this.$route.params.ID;
      if (ID > 0) {
        this.ReservationSessionSearch.DoctorID = ID;
      }
      this.$store.dispatch(
        "patientList/SearchReservationPackages",
        this.ReservationSessionSearch
      );
    },

    GetPatient() {
      this.$store
        .dispatch("patientList/SearchPatients", {})
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },

  created() {
    if (!moduleStatus.isRegistered) {
      this.$store.registerModule("StatusList", moduleStatus);
      moduleStatus.isRegistered = true;
    }

    this.$store.dispatch("StatusList/GetAllStatus");
    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");
    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleMedicalPackageCategory.isRegistered) {
      this.$store.registerModule(
        "MedicalPackageCategoryList",
        moduleMedicalPackageCategory
      );
      moduleMedicalPackageCategory.isRegistered = true;
    }
    this.$store.dispatch(
      "MedicalPackageCategoryList/GetAllMedicalPackageCategorys"
    );
     this.$store.dispatch("CountryList/GetAllPackageCountries");
    this.GetPatient();


    this.resetData();
        this.$store.dispatch("patientList/SearchReservationPackages", this.ReservationSessionSearch);
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
