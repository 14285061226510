/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.countries.unshift(item)
  },
  SET_Country(state, countries) {
    state.countries = countries
  },
  UPDATE_Country(state, Country) {
    const CountryIndex = state.countries.findIndex((p) => p.ID == Country.ID)
    Object.assign(state.countries[CountryIndex], Country)
},
SET_DoctorCountry(state, doctorCountries) {
  state.doctorCountries = doctorCountries;
},
SET_TopDestinations(state, topDestinations) {
  state.topDestinations = topDestinations;
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.countries.findIndex((p) => p.ID == itemId)
    state.countries.splice(ItemIndex, 1)
},
}
