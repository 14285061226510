/*=========================================================================================
  File Name: moduleDepartmentMutations.js
  Description: Department Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    ADD_ITEM(state, item) {
        state.MedicalPackageCategorys.unshift(item)
    },
    SET_MedicalPackageCategory(state, MedicalPackageCategorys) {
        state.MedicalPackageCategorys = MedicalPackageCategorys
    },
    UPDATE_MedicalPackageCategory(state, MedicalPackageCategory) {
        const MedicalPackageCategoryIndex = state.MedicalPackageCategorys.findIndex((p) => p.ID == MedicalPackageCategory.ID)
        Object.assign(state.MedicalPackageCategorys[MedicalPackageCategoryIndex], MedicalPackageCategory)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.MedicalPackageCategorys.findIndex((p) => p.ID == itemId)
        state.MedicalPackageCategorys.splice(ItemIndex, 1)
    },
}